<template>
  <div>
    <!-- WELCOME -->
    <section
      class="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover"
      style="background-image: url(assets/img/contact-image.jpg)"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">
            <!-- Heading -->
            <h1 class="display-2 fw-bold text-white">We’re Here to Help.</h1>

            <!-- Text -->
            <p class="lead text-white-75 mb-0">
              We always want to hear from you! Let us know how we can best help
              you and we'll do our very best.
            </p>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- SHAPE -->
    <div class="position-relative">
      <div class="shape shape-bottom shape-fluid-x text-light">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>

    <!-- INFO -->
    <section class="py-7 py-md-9 border-bottom border-gray-300" id="info">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <!-- Button -->
            <a
              href="#info"
              class="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13"
              data-scroll
            >
              <i class="fe fe-arrow-down"></i>
            </a>
          </div>
        </div>
        <!-- / .row -->
        <div class="row">
          <div class="col-12 col-md-4 text-center border-end border-gray-300">
            <!-- Heading -->
            <h6 class="text-uppercase text-gray-700 mb-1">Office Location</h6>

            <!-- Link -->
            <div class="mb-5 mb-md-0">
              <a href="#!" class="h4 text-primary">
                1490 Samuel Ademulegun Street, CBD, Abuja.
              </a>
            </div>
          </div>
          <div class="col-12 col-md-4 text-center border-end border-gray-300">
            <!-- Heading -->
            <h6 class="text-uppercase text-gray-700 mb-1">Call anytime</h6>

            <!-- Link -->
            <div class="mb-5 mb-md-0">
              <a href="tel:2349070362210" class="h4"> +(234) 907 036 2210 </a>
              <br />
              <a href="tel:2349070362220" class="h4"> +(234) 907 036 2220 </a>
              <br />
              <a href="tel:2349070362230" class="h4"> +(234) 907 036 2230 </a>
              <br />
              <a href="tel:2349070362240" class="h4"> +(234) 907 036 2240 </a>
              <br />
              <a href="tel:2349070362250" class="h4"> +(234) 907 036 2250 </a>
            </div>
          </div>
          <div class="col-12 col-md-4 text-center">
            <!-- Heading -->
            <h6 class="text-uppercase text-gray-700 mb-1">Office hours</h6>

            <!-- Link -->
            <a href="#!" class="h4"> Monday-Friday: 9AM - 5PM </a>
          </div>
        </div>
        <!-- / .row -->
      </div>
    </section>

    <!-- FORM -->
    <section class="pt-8 pt-md-11 pb-8 pb-md-14">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">
            <!-- Heading -->
            <h2 class="fw-bold">Let us hear from you directly!</h2>

            <!-- Text -->
            <p class="fs-lg text-muted mb-7 mb-md-9">
              We always want to hear from you! Let us know how we can best help
              you and we'll do our very best.
            </p>
          </div>
        </div>
        <!-- / .row -->
        <div class="row justify-content-center">
          <div class="col-12 col-md-12 col-lg-10">
            <!-- Form -->
            <form @submit.prevent="sendRequest()">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group mb-5">
                    <!-- Label -->
                    <label class="form-label" for="contactName">
                      Full name <span style="color: red">*</span>
                    </label>

                    <!-- Input -->
                    <input
                      class="form-control"
                      id="contactName"
                      type="text"
                      placeholder="Full name"
                      v-model="fields.fullname"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group mb-5">
                    <!-- Label -->
                    <label class="form-label" for="contactEmail"> Email </label>

                    <!-- Input -->
                    <input
                      class="form-control"
                      id="contactEmail"
                      type="email"
                      placeholder="hello@asolarnig.com"
                      v-model="fields.email"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group mb-5">
                    <!-- Label -->
                    <label for="contactPhone" class="form-label">
                      Phone number <span style="color: red">*</span>
                    </label>
                    <!-- Input -->
                    <input
                      class="form-control"
                      id="contactPhone"
                      type="text"
                      placeholder="08183780409"
                      v-model="fields.phone"
                    />
                  </div>
                </div>
              </div>
              <!-- / .row -->
              <div class="row">
                <div class="col-12">
                  <div class="form-group mb-7 mb-md-9">
                    <!-- Label -->
                    <label class="form-label" for="contactMessage">
                      What can we help you with?
                      <span style="color: red">*</span>
                    </label>

                    <!-- Input -->
                    <textarea
                      class="form-control"
                      id="contactMessage"
                      rows="5"
                      placeholder="Tell us what we can help you with!"
                      v-model="fields.message"
                    ></textarea>
                  </div>
                </div>
              </div>
              <!-- / .row -->
              <div class="row justify-content-center">
                <div class="col-auto">
                  <!-- Submit -->
                  <button
                    type="submit"
                    class="btn btn-primary lift"
                    :disabled="loading"
                  >
                    {{ loading ? "Sending..." : "Send message" }}
                  </button>
                </div>
              </div>
              <!-- / .row -->
            </form>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- SHAPE -->
    <div class="position-relative">
      <div class="shape shape-bottom shape-fluid-x text-dark">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GeneralService from "@/services/GeneralService";
export default {
  name: "Contact",
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {
      fields: {},
    };
  },
  methods: {
    sendRequest() {
      if (this.validateData()) {
        this.$store.dispatch("setLoading", true);
        GeneralService.sendContact(this.fields)
          .then((result) => {
            if (result.status) {
              this.success = result.status;
              this.showAlert("Successful", result.message, "success");
              this.fields = {}
            }
          })
          .catch((err) => {
            if (err.response === undefined) {
              this.showAlert(
                "Error occured",
                "Oops! took long to get a response",
                "warning"
              );
            } else {
              this.showAlert(
                err.response.data.status == false
                  ? "Error occured"
                  : "Server error",
                err.response.data.status == false
                  ? err.response.data.message
                  : "Internal server error",
                "warning"
              );
            }
          })
          .finally(() => {
            this.$store.dispatch("setLoading", false);
          });
      }
    },
    validateData() {
      if (this.fields.fullname) {
        if (this.fields.phone) {
          if (this.fields.message) {
            return true;
          } else {
            this.showAlert(
              "Validation error",
              "Your message is necessary",
              "warning"
            );
          }
        } else {
          this.showAlert(
            "Validation error",
            "Phone number is mandatory",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Kindly provide your full name",
          "warning"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
